import banner_celular from "../../../assets/images/banners/Banner_home_celular.png";

export const STYLE = {
  container: { padding: "0px", justifyContent: "center" },
  banner: {
    padding: "15px 2vw",
    minWidth: 320,
    height: 700,
    background: "#f8f9fa",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "column",
    display: "block",
    margin: "20px 0",
  },
  titleCategories: {
    textTransform: "uppercase",
    letterSpacing: 1,
    justifyContent: "center",
    padding: "0 4vw 30px",
    paddingTop: 10,
  },
  title: {
    width: 300,
    flexDirection: "column",
    letterSpacing: 1,
    justifyContent: "center",
    padding: "0 0px 30px",
    paddingTop: 10,
  },
  cursive: { color: "#008393", letterSpacing: 2 },
  subBanner: {
    padding: "10px 20px 100px",
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: "column",
    height: "400px",
    backgroundImage: `url(https://site.medita.com.br/imgs/banner_home_content_mobile.jpg)`,
    backgroundSize: "cover",
    backgroundPosition: "center 80%",
  },
  imageLeftScreens: {
    maxWidth: "90%",
    maxHeight: "500px",
    display: "block",
    margin: "0 auto",
  },
  txtMobile: {
    // maxWidth: '90%',
    minWidth: "60%",
    margin: "0 auto",
    display: "block",
    textAlign: "center",
    fontSize: "17px",
  },
  titleMobile: {
    fontSize: "20px",
  },
  btnDiasFree: {
    width: "260px !important",
    textTransform: "none",
  },
  leftSectionCelular: {
    // background: "#f8f9fb",
    margin: "0 0 30px",
  },
  rightSectionCelular: {
    justifyContent: "center",
    backgroundImage: `url(${banner_celular})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center bottom",
    backgroundSize: "cover",
    width: "100%",
    padding: "20px 50px",
    minHeight: "450px",
  },
  register: {
    display: "block",
    margin: "0 auto",
  },
  link: {
    fontWeight: "700",
    color: "#fff",
  },
  textTop: {
    fontSize: "16px !important",
    fontWeight: "500",
    margin: "10px auto",
  },
  bannerTop: {
    display: "block",
    margin: "0 auto",
    width: 400,
  },
  btnRegister: {
    fontSize: "16px !important",
    fontWeight: "500 !important",
    display: "block",
    margin: "20px auto",
    width: "266px !important",
    height: "56px",
    textTransform: "none",
    borderRadius: "28px",
  },
  imageLeftScreens: {
    maxWidth: "90%",
    display: "block",
    margin: "0 auto",
    height: "auto",
  },
  btnModal: {
    display: "block",
    margin: "20px auto 0",
    width: "290px",
    height: "60px",
  },
  textBase: {
    color: "#000",
    fontSize: "16px !important",
    fontWeight: "700 !important",
  },
  inputHome: {
    borderRadius: "30px 30px",
    border: "solid 1px #000",
  },
};
