import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import banner_meditacao from "../../../assets/images/banners/Banner_home.jpg";
import banner_first from "../../../assets/images/banners/banner_home_top.png";
import BannerSlider from "../../../components/BannerSlider";
import ButtonMedita from "../../../components/ButtonMedita";
import FooterSection from "../../../components/FooterSection";
import SectionMain from "../../../components/SectionMain";
import Title from "../../../components/Title";
import VideosCard from "../../../components/VideosCard";
import useGoToPage from "../../../hooks/useGoToPage";
import useVideosArray from "../../../hooks/useVideosArray";
import { STYLES } from "./styles";
import InputText from "../../../components/InputText";
import api from "../../../core/api";
import { useSnackbar } from "notistack";

const useStyles = makeStyles(STYLES);

const HomePageDesktop = () => {
  const classes = useStyles();
  const goToPage = useGoToPage();
  const videos = useVideosArray();
  const [email, setEmail] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const CheckEmailExists = async () => {
    try {
      await api.post("/auth/register/exists", { email }).then(async (res) => {
        goToPage("/cadastre-se");
      });
    } catch (e) {
      console.error({ e });
      enqueueSnackbar(`${e.response.data.message}` || "Ocorreu um erro", {
        variant: "error",
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
      });
      return false;
    }
  };

  return (
    <SectionMain noPadding>
      <SectionMain noPadding className={classes.banner}>
        <SectionMain item xs={6} className={classes.leftSection}>
          <Title size="xbig" fontWeight="700">
            Bem-vindo ao universo MeditaBem.
            <br />
            Meditação e bem-estar em um só lugar.
          </Title>
          <Title variant="secondary" className={classes.textTop}>
            O MeditaBem é um aplicativo de bem-estar voltado para o corpo, a
            mente e a alma. São centenas de áudios, vídeos, músicas e mantras de
            yoga, meditação, exercícios e terapias holísticas feitos na medida
            para melhorar o seu dia. Pratique onde quiser.
          </Title>
          <br />

          {localStorage.getItem("token") && (
            <Title
              variant="secondary"
              className={classes.textBase}
              align="center"
            >
              Vamos praticar?
            </Title>
          )}
          <br />

          {!localStorage.getItem("token") && (
            <>
              <Title
                variant="secondary"
                className={classes.textBase}
                align="center"
              >
                Vamos praticar? Informe seu email para criar sua conta.
              </Title>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "15px",
                }}
              >
                <InputText
                  onChange={({ target }) => {
                    setEmail(target.value);
                    localStorage.setItem("emailTemp", target.value);
                  }}
                  type="text"
                  label="Email"
                  className={classes.inputHome}
                />
                <ButtonMedita
                  backgroundColor="#316570"
                  onClick={() => CheckEmailExists()}
                  className={classes.btnModal}
                >
                  {"Vamos Começar?"}
                </ButtonMedita>
              </div>
            </>
          )}
        </SectionMain>
        <SectionMain item xs={6} className={classes.rightSection}>
          <img
            src={banner_first}
            className={classes.image}
            alt="Medita app frame mobile"
          />
        </SectionMain>
      </SectionMain>

      <SectionMain className={classes.container}>
        <BannerSlider />
      </SectionMain>

      <SectionMain className={classes.container}>
        <SectionMain className={classes.title}>
          <Title
            fontWeight="800"
            size="medium"
            align="center"
            capitalize="uppercase"
            letterSpacing={0.8}
          >
            {"EXPERIMENTE. SINTA-SE BEM."}
          </Title>
        </SectionMain>

        <VideosCard
          className={classes.videos}
          position={"center"}
          videos={videos.slice(0, 5)}
        />

        <SectionMain position="center">
          <ButtonMedita
            variant="outlined"
            onClick={() => goToPage("/experimente")}
            className={classes.verTodosBtn}
          >
            {"VER TODOS"}
          </ButtonMedita>
        </SectionMain>
      </SectionMain>

      <SectionMain style={{ width: "95%" }}>
        <SectionMain item xs={6} className={classes.leftSectionCelular}>
          <SectionMain style={{ display: "block" }}>
            <img
              src={banner_meditacao}
              className={classes.imageLeftScreens}
              alt="Medita app frame mobile"
            />
          </SectionMain>
        </SectionMain>
        <SectionMain item xs={6} className={classes.rightSectionCelular}>
          <Title color="#fff" align="center" className={classes.txtMobile}>
            <Title
              color="#fff"
              align="center"
              fontWeight="700"
              className={classes.titleMobile}
            >
              No MeditaBem você tem playlists em
              <br />
              áudio e vídeo.
            </Title>
            <br />
            Crie, ordene e personalize as playlists com suas músicas,
            meditações, mantras e vídeos favoritos. Monte suas sequências
            preferidas de áudios e vídeos para praticar, meditar, relaxar e
            dormir melhor.
          </Title>
        </SectionMain>
      </SectionMain>

      <SectionMain>
        <FooterSection />
      </SectionMain>
    </SectionMain>
  );
};

export default HomePageDesktop;
