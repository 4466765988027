import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { getSizeFont } from '../../../../utils/getSizeFont';
import { useAppContext } from '../../../../store/AppContext';

const useStyles = makeStyles({
  root: {
    cursor: 'pointer',
    letterSpacing: 1,
    color: (theme) => (theme.title === '|' ? '#2828284d' : '#282828'),
    fontWeight: (theme) => (theme.title === '|' ? '200' : '700'),
    fontSize: (theme) => (theme.title === '|' ? 17 : getSizeFont('small')),
    textTransform: 'uppercase',
    textDecorationLine: 'none',
  },
  register: {
    color: 'white',
    cursor: 'pointer',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    textDecorationLine: 'none',
    background: '#316570',
    padding: '13px 26px',
    borderRadius: '24px',
    height: '56px',
    fontWeight: '600 !important',
  },
});

const TopMenuUI = ({ link, title, goToPage }) => {
  const classes = useStyles({ title: title });
  const { setAppState } = useAppContext();
  const setRoute = (link) => {
    setAppState({ route: link });
  };

  return (
    <Link
      to={link}
      className={link === '/cadastre-se' ? classes.register : classes.root}
      onClick={() => {
        goToPage(link);
        setRoute(link);
      }}
    >
      {title}
    </Link>
  );
};

export default TopMenuUI;
