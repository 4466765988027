/* eslint-disable */
import { Link, makeStyles } from "@material-ui/core";
import React from "react";
import useGoToPage from "../../../hooks/useGoToPage";
import ButtonMedita from "../../ButtonMedita";
import SectionMain from "../../SectionMain";
import Title from "../../Title";

const useStyles = makeStyles({
  root: { padding: "0 2vw 100px", width: "100%" },
  link: {
    display: "flex",
    color: "#282828",
    fontSize: 14,
    textDecorationLine: "none",
    width: "100%",
    lineHeight: 2,
    alignItems: "center",
    cursor: "pointer",
  },
  linksContainer: {
    flexDirection: "column",
    padding: 10,
  },
  center: {
    margin: "0 auto",
    display: "block",
  },
  subLink: {
    padding: "0 10px",
    color: "#282828",
    fontSize: 14,
    textDecorationLine: "none",
    lineHeight: 2,
    alignItems: "center",
  },
  fixedbtn: {
    position: "fixed",
    bottom: "0",
    display: "block",
    margin: "20px auto",
    textTransform: "inherit",
    width: "247px !important",
    left: "calc(50% - 123.5px)",
  },
  title: {
    lineHeight: 3,
  },
  linksWrapper: {
    justifyContent: "center",
  },
});

const FooterSectionLinks = () => {
  const classes = useStyles();
  const goToPage = useGoToPage();

  // const footerLinksDesktop = [
  //   {
  //     label: "Contato",
  //     link: "/contato",
  //   },
  //   // {
  //   //   label: "Sobre Nós",
  //   //   link: "/sobre-nos",
  //   // },
  //   {
  //     label: "Termos de Uso e Privacidade",
  //     link: "/termos-de-uso",
  //   },
  // ];
  // const footerLinksMobile = [
  //   {
  //     label: "Contato",
  //     link: "/contato",
  //   },
  //   // {
  //   //   label: "Sobre Nós",
  //   //   link: "/sobre-nos",
  //   // },
  //   {
  //     label: "Termos de Uso",
  //     link: "/termos-de-uso",
  //   },
  // ];

  const links = [
    {
      title: "Experimente",
      routes: [
        {
          label: "Meditações",
          link: "/experimente?meditacoes",
        },
        {
          label: "Yoga",
          link: "/experimente?yoga",
        },
        {
          label: "Exercícios",
          link: "/experimente?exercicios",
        },
        {
          label: "Mantras",
          link: "/experimente?mantras",
        },
      ],
    },

    {
      title: "Parceiros",
      routes: [
        {
          label: "Seja um Parceiro",
          link: "/parceiros",
        },
      ],
    },

    {
      title: "Contato",
      routes: [
        {
          label: "Entre em contato",
          link: "/contato",
        },
        {
          label: "Parceria",
          link: "/parceiros",
        },
      ],
    },

    {
      title: "Siga o MeditaBem",
      routes: [
        {
          label: "Instagram",
          link: "https://www.instagram.com/meditabem",
          newPage: true,
        },
      ],
    },

    {
      title: "Comunidade",
      routes: [
        // {
        //   label: "Sobre nós",
        //   link: "/sobre-nos",
        // },
        {
          label: "Termos de uso e privacidade",
          link: "/politica-de-privacidade",
        },
      ],
    },

    // {
    //   title: 'Conheça o app',
    //   routes: [
    //     {
    //       label: 'Download',
    //       link:
    //         'https://play.google.com/store/apps/details?id=com.newmeditabemapp',
    //     },
    //     {
    //       label: 'Assinaturas',
    //       link:
    //         'https://play.google.com/store/apps/details?id=com.newmeditabemapp',
    //     },
    //     {
    //       label: 'Benefícios',
    //       link:
    //         'https://play.google.com/store/apps/details?id=com.newmeditabemapp',
    //     },
    //   ],
    // },
  ];

  return (
    <SectionMain className={classes.root}>
      <SectionMain noPadding className={classes.linksWrapper}>
        {links.map(({ title, routes }, index) => {
          return (
            <SectionMain
              key={`title-link-${title}`}
              xs={index === links.length - 1 ? 12 : 6}
              md={2}
              className={classes.linksContainer}
            >
              <Title
                fontWeight="800"
                align="left"
                letterSpacing={2}
                size="small"
              >
                {title}
              </Title>
              <ButtonMedita
                variant="outlined"
                className={classes.fixedbtn}
                onClick={() => goToPage("/medita-bem-planos")}
              >
                Baixe o MeditaBem
              </ButtonMedita>
              {routes.map(({ label, link, newPage }) =>
                newPage ? (
                  <a href={link} target="_blank" className={classes.link}>
                    {label}
                  </a>
                ) : (
                  <Link
                    key={`title-link-${link}`}
                    className={classes.link}
                    onClick={() => {
                      goToPage(link);
                    }}
                  >
                    {label}
                  </Link>
                )
              )}
            </SectionMain>
          );
        })}
      </SectionMain>
    </SectionMain>
  );
};

export default FooterSectionLinks;
