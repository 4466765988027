import { makeStyles } from '@material-ui/core';
import React from 'react';
import ButtonMedita from '../../ButtonMedita';
import InputText from '../../InputText';
import SectionMain from '../../SectionMain';
import Title from '../../Title';
import { STYLES } from './styles';
import { TEXTS } from './texts';
/**
 *
 * @param {import('react').HTMLAttributes} FooterSectionUI
 */

const useStyles = makeStyles(STYLES);

const FooterSectionUI = ({
  mobile,
  formValues,
  typeFooter,
  success = false,
  handleChangeInput,
  goToPage,
  loading,
  formikProps,
}) => {
  const classes = useStyles({ mobile, typeFooter });
  const texts = TEXTS;

  return (
    <SectionMain noPadding>
      <form
        action=''
        onSubmit={formikProps.handleSubmit}
        style={{ width: 'inherit' }}
      >
        <SectionMain className={classes.banner} id='footer-banner'>
          <SectionMain noPadding className={classes.overlayContainer}>
            <Title
              letterSpacing={2}
              color='#FFF'
              size={'medium'}
              fontWeight='700'
              className={classes.shadow}
              id='footer-title'
            >
              {texts[typeFooter].title}
            </Title>

            <Title color='#FFF' fontWeight='400' align='center' xs={12}>
              {success ? (
                <span className='flex-row'>
                  {texts[typeFooter].messageSuccess}
                </span>
              ) : (
                <span
                  className='flex-row'
                  className={classes.subText}
                  id='footer-text'
                >
                  <span className='flex-row'>
                    {texts[typeFooter].footerText}
                  </span>
                </span>
              )}
            </Title>

            <SectionMain
              noPadding
              position='center'
              direction='column'
              className={classes.inputs}
            >
              <InputText
                errorColor='#fff'
                value={formValues.first_name}
                errorMessage={
                  formikProps.touched.first_name &&
                  formikProps.errors.first_name
                }
                onChange={(e) =>
                  handleChangeInput('first_name', e.target.value)
                }
                disabled={loading}
                label='Nome'
              />
              <InputText
                errorColor='#fff'
                value={formValues.email}
                onChange={(e) => handleChangeInput('email', e.target.value)}
                errorMessage={
                  formikProps.touched.email && formikProps.errors.email
                }
                type='email'
                disabled={loading}
                label='E-mail'
              />
            </SectionMain>

            <ButtonMedita
              type='submit'
              size='small'
              variant='outlined'
              className={classes.mobileButton}
              disabled={loading}
            >
              {loading ? 'Enviando....' : 'Enviar'}
            </ButtonMedita>

            <br />
          </SectionMain>
        </SectionMain>
      </form>
    </SectionMain>
  );
};

export default FooterSectionUI;
