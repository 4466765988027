import { useEffect, useState } from "react";

export function useQueryParams() {
  const [params, setParams] = useState(undefined);

  useEffect(() => {
    const rawParams = new URLSearchParams(window.location.search);
    const parsedParams = Object.fromEntries(rawParams.entries());

    if (!parsedParams.token) {
      setParams(null);
    } else {
      setParams(parsedParams);
    }
  }, []);

  return params;
}
