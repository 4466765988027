import { makeStyles } from "@material-ui/core";
import React from "react";
import Modal from "react-modal";
import ButtonMedita from "../ButtonMedita";
import Title from "../Title";

Modal.defaultStyles.overlay.backgroundColor = "rgba(0,0,0,0.3)";

const useStyles = makeStyles({
  register: {
    color: "white",
    cursor: "pointer",
    padding: "14px 20px !important",
    background: "#316570 !important",
    fontWeight: "600 !important",
    letterSpacing: "1px",
    textTransform: "uppercase",
    textDecorationLine: "none",
    margin: "10px 0 15px 5px",
    borderRadius: "30px !important",
    width: "auto !important",
    height: "41px !important",
  },

  text: {
    fontSize: "14px",
    color: "white",
  },
});

const ButtonRegister = ({ openModal, title }) => {
  const classes = useStyles();

  return (
    <ButtonMedita onClick={openModal} className={classes.register}>
      <Title className={classes.text}>{title}</Title>
    </ButtonMedita>
  );
};

export default ButtonRegister;
