import React from 'react'
import TopMenuWrapper from './TopMenuWrapper'
import TopMenuUI from './TopMenuUI'

const TopMenu = () => {
	return (
		<TopMenuWrapper>
			{({ title, link, goToPage }) => <TopMenuUI title={title} link={link} goToPage={goToPage} />}
		</TopMenuWrapper>
	)
}

export default TopMenu
