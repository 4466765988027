import React, { useEffect, useState } from "react";
import Title from "../../Title";
import { makeStyles } from "@material-ui/core";
import icon from "../../../assets/images/check-circle.png";
import icon2 from "../../../assets/images/check-circle-white.png";
import ButtonMedita from "../../ButtonMedita";
import api from "../../../core/api";
import { useSnackbar } from "notistack";

const useStyles = makeStyles({
  p: {
    marginTLeft: 30,
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    paddingTop: "10px",
    paddingBottom: "10px",
    overflow: "auto",
  },
  pInfos: {
    fontWeight: "300",
    marginBottom: 0,
    textAlign: "left",
    color: "#848199",
    fontSize: "12px",
  },
  ul: {
    listStyle: "none",
    paddingLeft: 0,
  },
  li: {
    display: "flex",
    alignItems: "center",
    color: "#848199",
    paddingBottom: 10,
  },
  button: {
    position: "relative",
    bottom: 0,
    marginTop: 40,
    marginBottom: 20,
  },
  pInfosSelect: {
    fontWeight: "300",
    marginBottom: 0,
    textAlign: "left",
    color: "#fff",
    fontSize: "12px",
  },
  ulSelect: {
    listStyle: "none",
    paddingLeft: 0,
  },
  liSelect: {
    display: "flex",
    alignItems: "center",
    color: "#fff",
    paddingBottom: 10,
  },
});

const CardsRegister = ({ setSelectPlan, setFormStep, plans, statusPlan }) => {
  const plano = localStorage.getItem("plano");
  const [subsSite, setSubsSite] = useState(true);
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const snackbar = (message) => {
    enqueueSnackbar(`${message}`, {
      variant: "error",
      anchorOrigin: {
        horizontal: "right",
        vertical: "top",
      },
    });
  };

  const getSubscrition = async () => {
    const id = localStorage.getItem("id");
    if (id) {
      try {
        await api.get(`/subscriptions/users/${id}`).then(async ({ data }) => {
          if (data.transaction && data.transaction?.platform !== "site") {
            setSubsSite(false);
          }
        });
      } catch (e) {
        // snackbar("Ops.. algo de errado aconteceu. Tente novamente mais tarde!");
      }
    }
  };

  useEffect(() => {
    getSubscrition();
  }, []);

  return (
    <div className={classes.p}>
      {plans.map((item) =>
        item.id !== 3 ? (
          <div
            style={{
              width: "90%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              backgroundColor: "#F5F5F5",
              padding: 20,
              margin: 10,
              borderRadius: 20,
              borderBottom: "5px solid #fff",
            }}
          >
            <div style={{ padding: 10 }}>
              <Title size="xbig" fontWeight="700" color="#231D4F" align="left">
                R$ {item.price}{" "}
                <span className={classes.pInfos}> /{item.frequency}</span>
              </Title>
              <Title size="xbig" fontWeight="700" color="#231D4F" align="left">
                {item.name}
              </Title>
              <Title size="large" fontWeight="400" color="#848199" align="left">
                {item.description}
              </Title>
              <ul className={classes.ul}>
                {item.items.map((text) => (
                  <li key={item.id} className={classes.li}>
                    <img
                      src={icon}
                      alt={`icon of types contents ${icon}`}
                      style={{ marginRight: 10 }}
                    />
                    {text}
                  </li>
                ))}
              </ul>
              {item.footerDesc && (
                <ul className={classes.ul}>
                  {item.footerDesc.map((text) => (
                    <Title
                      size="large"
                      fontWeight="400"
                      color="#848199"
                      align="left"
                    >
                      {text}
                    </Title>
                  ))}
                </ul>
              )}
            </div>
            {statusPlan !== "PENDING" &&
              !(item.id === 1 && !item.activePlan) &&
              plano !== "S" &&
              plano !== "G" && (
                <ButtonMedita
                  backgroundColor="#316570"
                  className={classes.button}
                  disabled={item.id !== 1 && item.activePlan}
                  onClick={() => {
                    if (!subsSite)
                      return snackbar(
                        "Acesse o Aplicativo Medita para concluir essa ação"
                      );
                    if (item.id === 1) setFormStep(1);
                    setSelectPlan(item);
                  }}
                >
                  {item.id !== 1 && item.activePlan
                    ? "Seu Plano atual"
                    : "Escolher Plano"}
                </ButtonMedita>
              )}
          </div>
        ) : (
          <div
            style={{
              width: "90%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              backgroundColor: "#3EAF7D",
              padding: 20,
              margin: 10,
              borderRadius: 20,
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
            }}
          >
            <div
              style={{
                backgroundColor: "#316570",
                paddingLeft: 10,
                paddingRight: 10,
                width: "max-content",
                borderRadius: 50,
                alignSelf: "flex-end",
              }}
            >
              <Title fontWeight="700" color="#3EAF7D" align="right">
                MAIS POPULAR
              </Title>
            </div>
            <div
              style={{
                backgroundColor: "#fff",
                paddingLeft: 10,
                paddingRight: 10,
                width: "max-content",
                borderRadius: 50,
                alignSelf: "center",
                marginTop: 15,
              }}
            >
              <Title fontWeight="700" color="red" align="left">
                15% de desconto
              </Title>
            </div>
            <div style={{ padding: 10 }}>
              <Title size="xbig" fontWeight="700" color="#fff" align="left">
                R$ {item.price}{" "}
                <span className={classes.pInfosSelect}> /{item.frequency}</span>
              </Title>
              <Title size="xbig" fontWeight="700" color="#fff" align="left">
                {item.name}
              </Title>
              <Title size="large" fontWeight="400" color="#fff" align="left">
                {item.description}
              </Title>
              <ul className={classes.ulSelect}>
                {item.items.map((text) => (
                  <li key={item.id} className={classes.liSelect}>
                    <img
                      src={icon2}
                      alt={`icon of types contents ${icon}`}
                      style={{ marginRight: 10 }}
                    />
                    {text}
                  </li>
                ))}
              </ul>
            </div>
            {statusPlan !== "PENDING" && plano !== "S" && plano !== "G" && (
              <ButtonMedita
                disabled={item.activePlan}
                backgroundColor="#316570"
                className={classes.button}
                onClick={() => {
                  if (!subsSite)
                    return snackbar(
                      "Acesse o Aplicativo Medita para concluir essa ação"
                    );
                  if (item.id === 1) setFormStep(1);
                  setSelectPlan(item);
                }}
              >
                {item.activePlan ? "Seu Plano atual" : "Escolher Plano"}
              </ButtonMedita>
            )}
          </div>
        )
      )}
    </div>
  );
};

export default CardsRegister;
