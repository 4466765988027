import React, { useEffect } from "react";
import SectionMain from "../../components/SectionMain";
import { Hidden } from "@material-ui/core";
import HomePageDesktop from "./desktop";
import HomePageMobile from "./mobile";
import { useUpdateUser } from "../../hooks/useUpdateUser";

const HomePage = () => {
  const UpdateUser = async () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    await useUpdateUser();
  };
  useEffect(() => {
    UpdateUser();
  }, []);

  return (
    <SectionMain noPadding>
      <Hidden only={["xs", "sm"]}>
        <HomePageDesktop />
      </Hidden>
      <Hidden only={["md", "lg", "xl"]}>
        <HomePageMobile />
      </Hidden>
    </SectionMain>
  );
};

export default HomePage;
