import React from "react";
import SectionMain from "../../components/SectionMain";
import PlansUserPage from "./desktop";
import { Hidden } from "@material-ui/core";
import PlansUserPageMobile from "./mobile";

const PlanUserPage = () => {
  return (
    <SectionMain noPadding position="center">
      <Hidden only={["xs", "sm"]}>
        <PlansUserPage />
      </Hidden>
      <Hidden only={["md", "lg", "xl"]}>
        <PlansUserPageMobile />
      </Hidden>
    </SectionMain>
  );
};

export default PlanUserPage;
