import banner_celular from "../../../assets/images/banners/Banner_home_celular.png";

export const STYLES = {
  container: { padding: "0px 4vw 0px", justifyContent: "center" },
  banner: {
    background: "#f8f9fa",
    height: 400,
    justifyContent: "space-between",
    padding: "10px 6vw",
  },
  doMargin: {
    margin: "50px 0",
  },
  leftSection: { padding: "50px 0", maxHeight: 400 },

  image: {
    margin: "0 auto",
    maxWidth: "100%",
    height: "400px",
  },

  verTodosBtn: {
    margin: "20px 0 40px",
    width: "170px !important",
  },

  image_big: { width: "100%", maxWidth: "100%" },
  title: {
    width: 400,
    flexDirection: "column",
    letterSpacing: 1,
    justifyContent: "center",
    padding: "0 0 30px",
  },
  cursive: { color: "#008393", letterSpacing: 2 },
  subBanner: {
    padding: "8vw",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    height: "40vw",
    backgroundImage: `url(https://site.medita.com.br/imgs/banner_home_content_desktop.jpg)`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  titleMobile: {
    fontSize: "20px",
  },

  textTop: {
    fontSize: "16px !important",
    fontWeight: "500 !important",
    marginBottom: "20px",
  },

  textBase: {
    color: "#000",
    fontSize: "16px !important",
    fontWeight: "700 !important",
  },

  inputHome: {
    borderRadius: "30px 30px",
    border: "solid 1px #000",
  },

  leftSectionCelular: {
    // background: "#f8f9fb",
  },
  rightSectionCelular: {
    justifyContent: "center",
    backgroundImage: `url(${banner_celular})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center bottom",
    backgroundSize: "cover",
    width: "100%",
    padding: "20px 50px",
    minHeight: "560px",
  },
  txtMobile: {
    maxWidth: "90%",
    minWidth: "60%",
    margin: "0 auto",
    display: "block",
    textAlign: "center",
    fontSize: "15px",
  },
  link: {
    fontWeight: "700",
    color: "#fff",
  },
  imageLeftScreens: {
    maxWidth: "90%",
    maxHeight: "500px",
    display: "block",
    margin: "0 auto",
    position: "relative",
    top: "50%",
    transform: "translateY(-50%)",
  },
  videos: {
    margin: "0 15px",
  },
  btnModal: {
    margin: "20px 10px 40px",
  },
};
