import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import ReactSlick from "react-slick";
import banner1 from "../../../assets/images/slider/banner1.png";
import banner2 from "../../../assets/images/slider/banner2.png";
import banner3 from "../../../assets/images/slider/banner3.png";
import banner4 from "../../../assets/images/slider/banner4.png";
import banner5 from "../../../assets/images/slider/banner5.png";
import banner6 from "../../../assets/images/slider/banner6.png";
import banner7 from "../../../assets/images/slider/banner7.png";
import banner8 from "../../../assets/images/slider/banner8.png";

const BannerSliderWrapper = ({ children }) => {
  const bannerList = [
    banner1,
    banner2,
    banner3,
    banner4,
    banner5,
    banner6,
    banner7,
    banner8,
  ];

  const sliderSettings = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    autoplay: true,
    swipeToSlide: true,
    pauseOnHover: true,
    lazyKiad: "ondemand",
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const classes = makeStyles({
    sliderItemImage: {
      maxWidth: "95%",
      margin: "0 auto",
      borderRadius: "10px",
    },
    sliderWrapper: {
      margin: "150px 0 100px",
      padding: "0 30px",
    },
  })();

  return (
    <Grid item xs={12}>
      <ReactSlick {...sliderSettings} className={classes.sliderWrapper}>
        {bannerList.map((slide, index) => (
          <img
            key={`slide_${index}`}
            src={slide}
            alt={`Banner Slide ${index}`}
            className={classes.sliderItemImage}
          />
        ))}
      </ReactSlick>
    </Grid>
  );
};

export default BannerSliderWrapper;
