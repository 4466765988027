import { AppBar, Dialog, IconButton, makeStyles } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useContext, useEffect, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import logo from "../../../../assets/images/medita_logo_horizontal.png";
import { navButtons } from "../../../../core/navButtons";
import useDrawerMenu from "../../../../hooks/useDrawerMenu";
import useGoToPage from "../../../../hooks/useGoToPage";
import { AuthContext } from "../../../../store/AuthContext";
import ButtonMedita from "../../../ButtonMedita";
import ModalLogin from "../../../ModalLogin";
import SectionMain from "../../../SectionMain";
import ProfileDropdown from "../../TopMenu/TopMenuWrapper/ProfileDropdown";

const useStyles = makeStyles({
  appBar: {
    boxShadow: "none",
    background: "#f8f9fa",
    flexDirection: "row",
    position: "fixed",
    padding: "2vw 4vw 0",
    justifyContent: "space-between",
    transition: "0.5s",
    width: "100%",
  },
  changedColor: {
    boxShadow: "none",
    background: "#fff",
    flexDirection: "row",
    position: "fixed",
    padding: "2vw 4vw 0",
    justifyContent: "space-between",
    transition: "0.5s",
  },
  arrowIcon: {
    paddingTop: 20,
    paddingLeft: 15,
  },
  registerMessage: {
    justifyContent: "flex-end",
    alignItems: "center",
    height: "100%",
    flexDirection: "column",
    padding: 20,
  },
  container: {
    padding: "20px",
  },
  fixedbtn: {
    position: "fixed",
    bottom: "0",
    display: "block",
    margin: "20px auto",
    textTransform: "inherit",
    width: "247px !important",
    left: "calc(50% - 123.5px)",
  },
  btnModal: {
    display: "flex",
    width: "fit-content",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 0,
  },
});

let changedColor = true;

const DrawerMenuWrapper = ({ children }) => {
  const classes = useStyles();
  const [drawerMenu, setIsOpen] = useDrawerMenu();
  const [state, setState] = useState({ class: "appBar" });
  const { setToken, setData, data } = useContext(AuthContext);
  const [navItens, setNavItens] = useState(navButtons);

  const goToPage = useGoToPage();

  const onChangeNavbarColor = (event) => {
    if (window.scrollY <= 490 && changedColor === true) {
      changedColor = false;
      setState({ class: "appBar" });
    }
    if (window.scrollY >= 500 && changedColor === false) {
      changedColor = true;
      setState({ class: "changedColor" });
    }
  };

  const logout = async () => {
    setToken(undefined);
    setData(undefined);
    localStorage.clear();

    return (window.location.href = "/");
  };

  useEffect(() => {
    window.addEventListener("scroll", onChangeNavbarColor);

    return () => {
      window.removeEventListener("scroll", onChangeNavbarColor);
    };
  }, []);

  useEffect(() => {
    setNavItens(
      localStorage.getItem("token")
        ? [
            ...navButtons,
            {
              title: "minha conta",
              link: "/minha-conta",
              path: "",
            },
            {
              title: "sair",
              link: "",
              path: "",
            },
          ]
        : navButtons
    );
  }, [data]);

  return (
    <SectionMain miniPadding>
      <Dialog
        fullScreen
        open={drawerMenu === true}
        onClick={() => setIsOpen(false)}
      >
        <SectionMain className={classes.arrowIcon}>
          <IconButton size="small" onClick={() => setIsOpen(false)}>
            <IoIosArrowBack size={25} />
          </IconButton>
        </SectionMain>

        <SectionMain xs={5} className={classes.container}>
          {navItens.map(({ title, link, categories }) =>
            children({ title, link, categories, goToPage })
          )}
        </SectionMain>

        <SectionMain className={classes.registerMessage}>
          <ButtonMedita
            variant="outlined"
            className={classes.fixedbtn}
            onClick={() => goToPage("/medita-bem-planos")}
          >
            Baixe o MeditaBem
          </ButtonMedita>
        </SectionMain>
      </Dialog>

      <AppBar className={classes[state.class]}>
        <IconButton size="small" onClick={() => setIsOpen(true)}>
          <MenuIcon />
        </IconButton>
        <img
          alt="MeditaBem"
          src={logo}
          height="100%"
          width="200px"
          onClick={() => goToPage("/")}
        />

        {localStorage.getItem("token") ? (
          <div>&nbsp;</div>
        ) : (
          <IconButton size="small" style={{ marginTop: 15 }}>
            <ModalLogin />
          </IconButton>
        )}
      </AppBar>
    </SectionMain>
  );
};

export default DrawerMenuWrapper;
