import { makeStyles } from "@material-ui/core";
import React from "react";
import logo from "../../../../assets/images/logo.png";
import { navButtonsMenuDesktop } from "../../../../core/navButtons";
import useGoToPage from "../../../../hooks/useGoToPage";
import ModalCancelSubscription from "../../../ModalCancelSubscription";
import ModalLogin from "../../../ModalLogin";
import SectionMain from "../../../SectionMain";
import ProfileDropdown from "./ProfileDropdown";

const useStyles = makeStyles({
  appBar: {
    backgroundColor: "#fff",
    padding: "2vw 4vw 0",
    maxWidth: 1600,
    justifyContent: "space-between",
  },

  menu: {
    paddingTop: "0",
    alignItems: "center",
    justifyContent: "space-between",
    width: 950,
  },

  btnModal: {
    display: "flex",
    width: "fit-content",
    justifyContent: "center",
    alignItems: "center",
  },
});

const TopMenuWrapper = ({ children }) => {
  const classes = useStyles();
  const goToPage = useGoToPage();

  return (
    <SectionMain className={classes.appBar}>
      <img
        alt="MeditaBem"
        src={logo}
        width="200px"
        onClick={() => goToPage("/")}
        style={{
          paddingTop: "11px",
          width: "auto",
          height: "70px",
          cursor: "pointer",
        }}
      />

      <SectionMain noPadding className={classes.menu}>
        {navButtonsMenuDesktop.map(({ title, link }) => (
          <span key={`link-to-${link}`}>
            {children({ title, link, goToPage })}
          </span>
        ))}
        <span>
          {localStorage.getItem("token") ? <ProfileDropdown /> : <ModalLogin />}
        </span>
      </SectionMain>
    </SectionMain>
  );
};

export default TopMenuWrapper;
