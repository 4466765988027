import { Button, IconButton, makeStyles } from "@material-ui/core";
import React, { useState, useContext } from "react";
import Modal from "react-modal";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import { AuthContext } from "../../store/AuthContext";
import SectionMain from "../SectionMain";
import ButtonRegister from "../ModalLogin/ButtonRegister";
import ModalFormResult from "../ModalFormResult";
import { Close } from "@material-ui/icons";
import ButtonMedita from "../ButtonMedita";
import api from "../../core/api";
import { useEffect } from "react";
import { format, parseISO } from "date-fns";
import Title from "../Title";

const useStyles = makeStyles({
  planButton: {
    width: "100% !important",
    alignSelf: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },

  modal: {
    width: "500px",
    background: "#fff",
    margin: "200px auto",
    borderRadius: "13px",
    padding: "20px",
    background: "#eee",
    textAlign: "center",
  },
  modalTitle: {
    fontSize: 15,
    marginBottom: 20,
    textAlign: "center",
    width: "100%",
  },
  modalDeleteTitle: {
    fontSize: 20,
    marginBottom: 20,
    textAlign: "center",
    width: "100%",
  },
  p: {
    padding: 0,
    margin: 5,
    fontWeight: "bold",
    color: "#454545",
  },
  formMobile: {
    width: "auto",
    padding: 20,
  },
  buttonEnviar: {
    textTransform: "inherit",
    width: "247px !important",
  },
  register: {
    color: "red",
    cursor: "pointer",
    padding: "14px 20px !important",
    fontWeight: "600 !important",
    letterSpacing: "1px",
    textTransform: "uppercase",
    textDecorationLine: "none",
    margin: "10px 0 15px 5px",
    borderRadius: "30px !important",
    width: "300px !important",
    height: "50px !important",
  },

  text: {
    fontSize: "16px",
    width: "50px",
    color: "red",
    fontWeight: "bold",
  },
});

const ModalCancelSubscription = ({ subscription }) => {
  const [loadingSubscription, setLoadingSubscription] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalSuccess, setIsOpenSuccess] = React.useState(false);
  const [modalHeader, setModalHeader] = React.useState("");
  const [modalBody, setModalBody] = React.useState("");

  const [data, setData] = useState("");
  const [idSub, setIdSub] = useState("");

  const snackbar = (message) => {
    enqueueSnackbar(`${message}`, {
      variant: "error",
      anchorOrigin: {
        horizontal: "right",
        vertical: "top",
      },
    });
  };

  //   const openModalErro = (header, body) => {
  //     setErroIsOpen(true);
  //     setModalHeader(header);
  //     setModalBody(body);
  //   };

  //   const closeModalErro = () => {
  //     setErroIsOpen(false);
  //   };

  useEffect(() => {
    if (subscription) {
      setData(
        subscription?.endDate
          ? format(parseISO(subscription?.endDate), "dd/MM/yyyy")
          : null
      );
      setIdSub(subscription.id);
    }
  }, [subscription]);

  const openModalResult = (header, body) => {
    setIsOpenSuccess(true);
    setModalHeader(header);
    setModalBody(body);
  };

  function openModal() {
    setIsOpen(true);
    if (data) setData(data);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function closeModalSuccess() {
    setIsOpenSuccess(false);
    closeModal();
  }

  const cancelSubscription = async () => {
    const id = localStorage.getItem("id");
    console.log(id, idSub);
    try {
      await api.delete(`/subscriptions/${id}`).then(async (res) => {
        openModalResult(`Sucesso!`, "Sua assinatura foi cancelada.");
      });
    } catch (e) {
      console.log(e);
      // openModalResult(`Sucesso!`, "Sua assinatura foi cancelada.");
      closeModal();
      snackbar("Ops.. algo de errado aconteceu. Tente novamente mais tarde!");
    }
  };

  const classes = useStyles();

  return (
    <SectionMain noPadding>
      {new Date(subscription?.endDate) > new Date() ? (
        subscription?.cancelledAt ? (
          <Title className={classes.text}>
            Você poderá continuar acessando todos os conteúdos do seu aplicativo
            MeditaBem até o dia <b>{data || null}</b>
          </Title>
        ) : (
          subscription?.id && (
            <Button onClick={openModal} className={classes.register}>
              {/* Cancelar Assinatura */}
              <Title className={classes.text}>Cancelar Assinatura</Title>
            </Button>
          )
        )
      ) : null}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Cancelar Assinatura"
        className={classes.modal}
      >
        <div style={{ display: "flex" }}>
          <IconButton size="large" onClick={closeModal}>
            <Close />
          </IconButton>
        </div>
        <h2 className={classes.modalDeleteTitle}>Cancelar assinatura?</h2>

        <p>
          Você poderá continuar acessando todos os conteúdos do seu aplicativo
          MeditaBem até o dia <b>{data || null}</b>.
        </p>

        <ButtonMedita
          type="button"
          onClick={cancelSubscription}
          // disabled={loadingSubscription}
          // className={classes.buttonEnviar}
        >
          Confirmar
          {/* {loadingSubscription ? (
          <>
            <span style={{ color: "white" }}>Aguarde... </span>
            <IconButton style={{ color: "white" }} size="large">
              <Sync />
            </IconButton>
          </>
        ) : (
          "Enviar código"
        )} */}
        </ButtonMedita>

        {/* <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Assinatura"
        className={classes.modal}
      ></Modal>*/}
        <ModalFormResult
          isOpen={modalSuccess}
          closeModal={closeModalSuccess}
          title={modalHeader}
          text={modalBody}
        ></ModalFormResult>
      </Modal>
    </SectionMain>
  );
};

export default ModalCancelSubscription;
